<template>
  <v-card>
    <v-card-title :class="`main-${subject.color}-bg white--text`">
      <span class="text-h5">{{ lessonFormTitle }}</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-form v-model="isLessonFormValid" ref="lessonForm">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="lessonFormData.title"
                label="Title"
                :rules="[validators.required, isLessonTitleUnique]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="lessonFormData.module"
                label="Content"
                class="rounded"
                hide-details="auto"
                dense
                :rules="[inputCheck('file', file, lessonFormData.module)]"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-row v-if="!loadStudents">
                <div class="d-flex justify-center align-center">
                  <v-banner two-line shaped>
                    <v-icon slot="icon" color="info" size="25">
                      mdi-information-outline
                    </v-icon>
                    Would you like to show a list of enrolled students to
                    immediately assign this lesson?
                  </v-banner>
                  <v-btn
                    class="d-none d-sm-block"
                    color="info"
                    small
                    @click="getStudents(subject.id)"
                  >
                    Show Students
                  </v-btn>
                </div>
                <v-btn
                  class="d-sm-none"
                  block
                  color="info"
                  small
                  @click="getStudents(subject.id)"
                >
                  Show Students
                </v-btn>
                <v-divider></v-divider>
              </v-row>
              <v-row v-else>
                <v-sheet
                  v-if="loadingStudents"
                  color="grey lighten-4"
                  class="pa-3"
                  width="100%"
                >
                  <v-skeleton-loader
                    class="mx-auto"
                    type="list-item-two-line, divider, list-item, divider, list-item"
                  ></v-skeleton-loader>
                </v-sheet>
                <v-col cols="12" v-else>
                  <div v-if="studentsEnrolled.length > 0">
                    <StudentEnrolled
                      @selected="lessonFormData.studentIds = $event"
                      :selectedStudents="lessonFormData.studentIds"
                      :studentsEnrolled="studentsEnrolled"
                    />
                  </div>
                  <div v-else>
                    <v-banner two-line>
                      <v-icon slot="icon" color="info" size="36">
                        mdi-information-outline
                      </v-icon>
                      No Students Found.
                    </v-banner>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="lessonFormData.quarter"
                label="Quarter"
                clearable
                :items="quarterOptions"
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-progress-linear
                v-if="uploadProgress > 0"
                class="my-4"
                :buffer-value="uploadProgress"
                stream
                height="30"
                rounded
                color="teal"
              >
                <i>{{ uploadStatus }}</i>
              </v-progress-linear>
              <v-file-input
                v-if="uploadProgress == 0"
                :loading="fileLoading"
                :disabled="fileLoading"
                :value="file"
                show-size
                hide-details="auto"
                label="Choose File"
                @change="setFileUpload($event)"
                :rules="[inputCheck('module', lessonFormData.module, file)]"
              />
              <v-btn
                v-if="downloadUrl"
                @click="downloadFile(downloadUrl, file.name)"
                :loading="downloading"
                class="my-2 py-2 float-right"
                x-small
                color="info"
              >
                <v-icon x-small>mdi-download</v-icon>
                Download
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h4 v-if="fileLoading"><i>Loading Preview...</i></h4>
              <h4 v-if="!fileLoading && fileType === 'noPreviewAvailable'">
                <i>No Available Preview</i>
              </h4>
              <video
                :src="dataUrl"
                controls
                width="100%"
                v-if="fileType === 'video'"
              />
              <v-img
                :src="dataUrl"
                contain
                max-width="100%"
                v-if="fileType === 'image'"
              ></v-img>
              <iframe
                :src="dataUrl"
                width="100%"
                height="500px"
                v-if="fileType === 'pdf'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="uploadProgress > 0"
        color="error"
        class="ma-2 white--text px-3"
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isLessonFormValid"
        :loading="loadingSubmit"
        @click="submitLessonForm"
        color="success"
        class="ma-2 white--text px-3"
      >
        <v-icon left dark> mdi-send </v-icon>
        {{ formActionLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from "@/helpers/validationHelper";
import { mapGetters } from "vuex";
import mimetypes from "@/lib/utilities/mimetypes";
import StudentEnrolled from "@/components/StudentEnrolled";
export default {
  name: "LessonForm",
  components: {
    StudentEnrolled,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    quarterOptions: {
      type: Array,
      default: () => [1, 2, 3, 4],
    },
    lessonFormTitle: {
      type: String,
      default: "Upload Module",
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    lessonData: {
      type: Object,
      default: () => ({
        title: "",
        module: "",
        file: "",
        subjectId: "",
        quarter: "",
        teacherId: "",
        studentIds: [],
      }),
    },
  },
  data() {
    return {
      isLessonFormValid: false,
      loadingSubmit: false,
      loadStudents: false,
      loadingStudents: false,
      validators: validators,
      mimetypes: mimetypes,
      lessonFormData: {
        title: "",
        module: "",
        file: "",
        subjectId: "",
        quarter: "",
        teacherId: "",
        studentIds: [],
      },
      file: null,
      fileType: "",
      fileLoading: false,
      downloadUrl: false,
      downloading: false,
      dataUrl: "",
      studentsEnrolled: [],
    };
  },
  computed: {
    ...mapGetters({
      getLessonTitles: "lessonsModule/getLessonTitles",
    }),
    formActionLabel: function () {
      return this.isUpdating ? "Update" : "Submit";
    },
    uploadProgress() {
      return this.$store.state.lessonsModule.uploadProgress;
    },
    uploadStatus() {
      return this.uploadProgress > 0
        ? this.uploadProgress === 100
          ? "Complete"
          : "Uploading"
        : "";
    },
  },
  methods: {
    async getStudents(subjectId) {
      this.loadStudents = true;
      this.loadingStudents = true;
      if (this.studentsEnrolled.length === 0) {
        await this.$store.dispatch("_subjectsModule/getSubjectById", subjectId);
        this.studentsEnrolled =
          this.$store.getters["_subjectsModule/getStudentsEnrolled"];
      }

      this.loadingStudents = false;
    },
    inputCheck(name, field, value) {
      return (field == "" || field == null) && (value == null || value == "")
        ? `Please input a ${name} content atleast`
        : true;
    },
    isLessonTitleUnique(title) {
      if (
        this.isUpdating &&
        this.lessonData.title?.toLowerCase() === title?.toLowerCase()
      ) {
        return true;
      }
      return this.getLessonTitles.includes(title?.toLowerCase())
        ? "Title already used"
        : true;
    },
    setFileUpload(file) {
      if (file) {
        this.downloadUrl = false;
        //set file info locally (component)
        this.file = file;

        //check file type if preview available
        const type = file.type;

        this.fileLoading = true;
        this.fileType = this.checkFile(type);

        if (this.fileType) {
          this.previewFile();
        }

        setTimeout(() => (this.fileLoading = false), 1000);
      } else {
        this.clearUploadFields();
      }
    },
    checkFile(type) {
      //check file upload for mimetypes
      if (mimetypes.video.includes(type)) {
        return "video";
      } else if (mimetypes.image.includes(type)) {
        return "image";
      } else if (mimetypes.pdf.includes(type)) {
        return "pdf";
      } else {
        return "noPreviewAvailable";
      }
    },

    previewFile() {
      let reader = new FileReader();
      reader.onload = (event) => {
        this.dataUrl = event.target.result;
      };

      reader.readAsDataURL(this.file);
    },
    downloadFile(fileUrl, fileName) {
      //create an xml http request and allow cors in firebase to download
      const self = this;
      self.downloading = true;
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", fileUrl, true);
      xhr.send();
      xhr.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          const downloadUrl = window.URL.createObjectURL(this.response);
          const anchor = document.createElement("a");
          anchor.href = downloadUrl;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          self.downloading = false;
        }
      };
    },
    async submitLessonForm() {
      this.loadingSubmit = true;
      const revalidatedForm = this.$refs.lessonForm.validate();
      this.lessonFormData.subjectId = this.subject.id;
      if (revalidatedForm) {
        await this.$store.dispatch("lessonsModule/submitLessonForm", {
          file: this.file,
          lesson: this.lessonFormData,
          isUpdating: this.isUpdating,
        });
      }
      this.$emit("setQuarter", this.lessonFormData.quarter);
      this.loadingSubmit = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$refs.lessonForm.reset();
      this.clearUploadFields();
      this.lessonFormData = {
        title: "",
        module: "",
        file: "",
        subjectId: "",
        quarter: "",
        teacherId: "",
        studentIds: [],
      };
      this.loadingStudents = false;
      this.$store.commit("lessonsModule/clearLessonData");
      this.$emit("closeDialog", this.isUpdating ? "edit" : "add");
    },
    clearUploadFields() {
      this.file = null;
      this.fileType = "";
      this.fileLoading = false;
      this.downloadUrl = false;
      this.dataUrl = "";
    },
  },
  watch: {
    lessonData: {
      async handler(newLessonData) {
        if (this.isUpdating) {
          this.lessonFormData = newLessonData;
          if (newLessonData.studentIds?.length > 0) {
            await this.getStudents(this.subject.id);
          }
          const fileName = newLessonData.file;
          if (fileName) {
            this.fileLoading = true;
            //get filemeta from firebase
            const fileMeta = await this.$store.dispatch(
              "lessonsModule/getFileMeta",
              fileName
            );

            if (fileMeta) {
              this.file = {
                name: fileMeta.name.split("-")[2],
                size: fileMeta.size,
              };
              //check file type for preview
              this.fileType = this.checkFile(fileMeta.contentType);
            }

            this.dataUrl = await this.$store.dispatch(
              "lessonsModule/getFileUrl",
              fileName
            );
            this.downloadUrl = this.dataUrl;
            this.fileLoading = false;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
