<template>
  <v-data-table
    :search="searchInput"
    :headers="lessonHeaders"
    :items="filteredLessons"
    :loading="lessonsLoading"
    :footer-props="{
      itemsPerPageOptions: lessonsPerPage,
    }"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle rounded class="pa-2 ml-sm-n8 ml-md-n15">
        <v-btn
          :to="{ name: 'Module', params: { id: item.id } }"
          class="remove-toggled-state"
          small
          color="success"
        >
          <v-icon small color="white"> mdi-eye </v-icon>
        </v-btn>
        <v-btn
          class="remove-toggled-state"
          small
          color="primary"
          @click="openDialog('edit', item.id)"
        >
          <v-icon small color="white"> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="remove-toggled-state"
          small
          color="error"
          @click="openDialog('delete', item.id)"
        >
          <v-icon small color="white">mdi-delete</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LessonList",
  props: {
    subject: {
      type: Object,
      required: true,
    },
    searchInput: {
      type: String,
      default: "",
    },
    filterLessonsByQuarter: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      lessonsPerPage: [10, 15, 25, 50, 100],
      lessonHeaders: [
        {
          text: "Lesson Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      lessons: (state) => state.lessonsModule.lessons,
      lessonsLoading: (state) => state.lessonsModule.lessonsLoading,
    }),
    filteredLessons() {
      let filteredLessons = this.lessons ? this.lessons : [];
      if (this.filterLessonsByQuarter) {
        filteredLessons = filteredLessons?.filter(
          (lesson) => lesson.quarter == this.filterLessonsByQuarter
        );
      }

      return filteredLessons;
    },
  },
  methods: {
    openDialog(action, lessonId) {
      this.$emit("openDialog", { action: action, lessonId: lessonId });
    },
  },
  async mounted() {
    const subjectId = this.subject.id;
    await this.$store.dispatch("lessonsModule/getLessons", subjectId);
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
