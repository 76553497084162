<template>
  <v-card class="elevation-2">
    <v-dialog v-model="lessonAddDialog" max-width="800px">
      <LessonForm
        lessonFormTitle="Upload Module"
        :subject="subject"
        :quarterOptions="quarterOptions"
        @setQuarter="filterLessonsByQuarter = $event"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="lessonEditDialog" max-width="800px">
      <LessonForm
        lessonFormTitle="Update Module"
        :subject="subject"
        :quarterOptions="quarterOptions"
        :isUpdating="true"
        :lessonData="lessonData"
        @setQuarter="filterLessonsByQuarter = $event"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="lessonDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Delete Lesson</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <span class="text-h6">Are you sure you want to delete lesson ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('delete')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="deleteLesson(subject.id)"
            :loading="deletingLesson"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-delete </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title
      class="d-block d-sm-flex justify-sm-start justify-sm-space-between align-center"
    >
      <h3 class="text-center">Modules Bank</h3>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="success"
          class="px-4"
          light
          medium
          @click="openDialog({ action: 'add', lessonId: null })"
        >
          <v-icon left> mdi-plus-circle </v-icon>
          Upload Module
        </v-btn>
      </v-card-actions>
    </v-card-title>
    <v-card-text
      class="d-sm-flex justify-sm-end justify-sm-space-between align-center flex-row-reverse"
    >
      <v-select
        class="pa-2 d-sm-none"
        :items="quarterOptions"
        :value="filterLessonsByQuarter"
        @change="filterLessonsByQuarter = $event"
        label="Quarter :"
        item-color="red"
        outlined
        dense
        flat
        hide-details
        style="max-width: 100px"
      ></v-select>
      <SearchQuery
        @search="searchInputQuery = $event"
        style="max-width: 400px"
      />
    </v-card-text>
    <div class="d-none d-sm-flex align-sm-end justify-sm-space-between">
      <v-btn-toggle
        v-model="filterLessonsByQuarter"
        :class="`main-${subject.color}-text`"
        mandatory
      >
        <v-btn
          v-for="quarter in quarterOptions"
          :key="quarter"
          :value="quarter"
          large
          text
        >
          Quarter {{ quarter }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-divider></v-divider>
    <v-card-text>
      <LessonList
        :subject="subject"
        :searchInput="searchInputQuery"
        :filterLessonsByQuarter="filterLessonsByQuarter"
        @openDialog="openDialog($event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import LessonList from "@/components/LessonList";
import LessonForm from "@/components/LessonForm";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Modules",
  },
  name: "LessonIndex",
  components: {
    SearchQuery,
    LessonList,
    LessonForm,
  },
  data() {
    return {
      filterLessonsByQuarter: "",
      searchInputQuery: "",
      lessonAddDialog: false,
      lessonEditDialog: false,
      lessonDeleteDialog: false,
      deletingLesson: false,
    };
  },
  computed: {
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    quarterOptions() {
      return this.$store.state.subjectsModule.quarterOptions;
    },
    ...mapGetters({
      getLessonData: "lessonsModule/getLessonData",
    }),
    lessonData() {
      return this.getLessonData;
    },
  },
  methods: {
    async openDialog(dialogObject) {
      if (dialogObject.lessonId) {
        await this.$store.dispatch(
          "lessonsModule/getLessonById",
          dialogObject.lessonId
        );
      }
      switch (dialogObject.action) {
        case "add":
          this.lessonAddDialog = true;
          break;
        case "edit":
          this.lessonEditDialog = true;
          break;
        case "delete":
          this.lessonDeleteDialog = true;
          break;
      }
    },
    closeDialog(dialog) {
      switch (dialog) {
        case "add":
          this.lessonAddDialog = false;
          break;
        case "edit":
          this.lessonEditDialog = false;
          break;
        case "delete":
          this.lessonDeleteDialog = false;
          break;
      }
    },
    async deleteLesson(subjectId) {
      const lesson = this.$store.getters["lessonsModule/getLessonData"],
        lessonId = lesson.id,
        lessonFile = lesson.file,
        payload = {
          lessonId,
          subjectId,
        };
      this.deletingLesson = true;
      if (lessonFile) {
        await this.$store.dispatch("lessonsModule/deleteFile", lessonFile);
      }
      await this.$store.dispatch("lessonsModule/deleteLessonById", payload);
      this.deletingLesson = false;
      this.closeDialog("delete");
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
